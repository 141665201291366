import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'

import PointLIstStoreModule from './storeModule'

export default function () {
  const { makeToast } = useToast()
  const APP_STORE_MODULE_NAME = 'app-product-list'

  const initialSearchParams = ref({})
  const currentPage = ref(parseInt(router.history.current.query.page, 10) || 1)
  const searchParams = computed(() => {
    const routerQuery = router.history.current.query
    return {
      page: currentPage.value,
      size: parseInt(routerQuery.size, 10) || 20,
      total: parseInt(routerQuery.total, 10) || 0,
      ...initialSearchParams.value,
    }
  })
  const syncronizeParamsWithUrl = () => {
    const routerQuery = router.history.current.query
    initialSearchParams.value = Object
      .keys(routerQuery)
      .reduce((previoutValue, currentValue) => {
        if (['page', 'size', 'total'].includes(currentValue)) {
          return previoutValue
        }
        if (currentValue === null || currentValue === '') {
          return previoutValue
        }
        if (typeof routerQuery[currentValue] === 'string') {
          return {
            ...previoutValue,
            [currentValue]: [routerQuery[currentValue]],
          }
        }
        return {
          ...previoutValue,
          [currentValue]: routerQuery[currentValue],
        }
      }, {})
  }

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => { })
  }

  const categoryOptions = [
    {
      label: '상태',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'ACTIVE', value: 'ACTIVE' },
        { text: 'INACTIVE', value: 'INACTIVE' },
      ],
    },
    {
      label: '탭',
      paramName: 'type',
      format: 'checkbox',
      options: [
        { text: 'HOME', value: 'HOME' },
        { text: 'MARKET', value: 'MARKET' },
      ],
    },
  ]

  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'name', label: 'name' },
    { key: 'state', label: 'state' },
    { key: 'type', label: 'type' },
    { key: 'start_at', label: 'start_at' },
    { key: 'end_at', label: 'end_at' },
    { key: 'created_at', label: '등록날짜' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const shortcutSearch = params => {
    initialSearchParams.value = {
      ...params,
    }
    router.replace({
      query: { ...searchParams.value, page: 1, size: 20 },
    }).catch(() => { })
    fetchItems()
  }

  const search = params => {
    initialSearchParams.value = {
      ...initialSearchParams.value,
      ...params,
    }
    currentPage.value = 1
    router.replace({
      query: { ...searchParams.value, page: currentPage.value },
    }).catch(() => { })
    fetchItems()
  }

  const changePage = params => {
    currentPage.value = params.page
    router.replace({
      query: { ...searchParams.value, ...params },
    }).catch(() => { })
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, PointLIstStoreModule)
    syncronizeParamsWithUrl()
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    shortcutSearch,
  }
}
