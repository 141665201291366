<template>
  <div>
    <TagbyFilter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'app-widget-item-group-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </TagbyFilter>
    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'app-widget-item-group-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(start_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>

      <template #cell(end_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>
    </TagbyList>
  </div>
</template>

<script>
import {
  BLink,
  BImg,
  BButton,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import resolveValue from '@/utils/resolveValue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    TagbyFilter,
    TagbyList,
  },
  setup() {
    const {
      resolveDateTime,
    } = resolveValue()
    const resolveRedirectType = redirectPath => {
      if (redirectPath == null || redirectPath === {}) return null
      const redirectType = redirectPath?.route_name?.[0]
      if (redirectType === 'new_campaign_detail') return 'campaign_detail'
      return 'link'
    }
    const resolveRedirectValue = redirectPath => {
      const redirectType = resolveRedirectType(redirectPath)
      if (redirectType === 'campaign_detail') return redirectPath?.param?.[0]?.hero_key
      if (redirectType === 'link') return redirectPath?.route_name?.[0]
      return null
    }
    return {
      resolveDateTime,
      resolveRedirectType,
      resolveRedirectValue,
      ...viewModel(),
    }
  },
}
</script>
